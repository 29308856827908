import { Component, Input, OnInit } from '@angular/core';
import { SuccessStory } from 'src/app/models/success-stories/SuccessStories.model';

@Component({
  selector: 'dep-success-story-thumbnail',
  templateUrl: './success-story-thumbnail.component.html',
  styleUrls: ['./success-story-thumbnail.component.scss']
})
export class SuccessStoryThumbnailComponent implements OnInit {
  @Input() successStory: SuccessStory;
  @Input() successStoryIndex: number;
  @Input() isHighlighted: boolean = false;
  public truncatedDescription: string;

  constructor() { }
  
  ngOnInit(): void {
    this.truncatedDescription = this.successStory.description.length > 150 ? this.successStory.description.substring(0, 150) + '...' : this.successStory.description;
  }

}
