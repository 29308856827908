import { Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'dep-single-circle-background',
  templateUrl: './circle-background.component.html',
  styleUrls: ['./circle-background.component.scss']
})
export class SingleCircleBackgroundComponent {
  constructor(private renderer: Renderer2,
    private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.forceReflow();
  }

  private forceReflow(): void {
    const svgElement = this.el.nativeElement.querySelector('svg');
    if (svgElement) {
      this.renderer.setStyle(svgElement, 'display', 'none');
      this.renderer.setStyle(svgElement, 'display', 'block');
    }
  }
}
