<article class="forum-card" *ngIf="content" >
    <a class="mobile-link" [routerLink]="'/tribunes/'+(forum.tags[0].name | formatUrl ) + '/' +forum.id+'-'+forum.url+'.html'"></a>
    <div class="forum-card__image">
        <img src="{{content.authorPhotoProfile}}" alt="{{forum?.altImage}}" loading="lazy" class="customised">
    </div>
    <div class="forum-card__content">
        <div class="forum-card__header">
            <p class="forum-card__categories">
                <span class="tag" *ngFor="let category of forum?.tags">{{category.name}}</span>
            </p>
            <p class="quote">“{{content.quote}}”</p>
        </div>
        <div class="forum-card__footer">
            <div class="forum-card__footer-wrapper">
                <div class="forum-card__image--mobile">
                    <img src="{{content.authorPhotoProfile}}" alt="{{forum?.altImage}}" loading="lazy"
                        class="customised">
                </div>
                <div class="forum-card__author">
                    <p class="title">{{content.authorName}}</p>
                    <p class="role">{{content.authorRole}}</p>
                </div>
            </div>
            <a [routerLink]="'/tribunes/'+(forum.tags[0].name | formatUrl ) + '/' +forum.id+'-'+forum.url+'.html'"
                class="ds-btn ds-btn--secondary">
                <span>Lire</span>
                <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
            </a>
            <dep-icon [icon]="'icon-arrow_forward'" class="icon-mobile"></dep-icon>
        </div>
    </div>
</article>