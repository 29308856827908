import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NEWSLETTER_TYPES } from 'src/app/mocks/newsletter-type/mock-newsletter.model';
import { Forum } from 'src/app/models/forums/forum.model';
import { CookieNewsletterService } from 'src/app/services/cookie-newsletter/cookie-newsletter.service';
import { ForumService } from 'src/app/services/forums/forum.service';
import { ServerErrorInterceptorService } from 'src/app/shared/services/servererrorinterceptor.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { Location } from '@angular/common';
import { Suggestion, TypeArticle } from 'src/app/models/suggestions/Suggestions.model';
import { SuggestionService } from 'src/app/services/suggestions/suggestion.service';

@Component({
  selector: 'dep-forum-detail',
  templateUrl: './forum-detail.component.html',
  styleUrls: ['./forum-detail.component.scss']
})
export class ForumDetailComponent implements OnInit, OnDestroy {

  @Input() public forumId: number;
  public forum: Forum;
  public clipboardCopySuccess = false;
  public isOpen: boolean;
  public hasLiked: boolean = false;
  private clipboardmessageDuration = 4000;
  private title: string;
  private metaDescription: string;
  public newsletter = NEWSLETTER_TYPES[1];
  private fullUrl: string;
  public isOpenPopup : boolean = false;
  public suggestions: Suggestion[] = [];
  

  constructor(private meta: Meta,
    private titleService: Title,
    private forumService: ForumService,
    private suggestionService: SuggestionService,
    private activateRoute: ActivatedRoute,
    private serverErrorInterceptor: ServerErrorInterceptorService,
    private renderer: Renderer2,
    private location: Location,
    @Inject(DOCUMENT) document: any,
    private cookieNewsletterService: CookieNewsletterService,
    private sharedService: SharedService) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(params => {
      this.forumId = params.selectedForum.split('-')[0];
      this.getForumById();
    });
    this.isOpen = this.cookieNewsletterService.checkCookie(this.newsletter.cookieName);
    this.fullUrl = window.location.href;
  }


  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
    this.meta.removeTag("name='twitter:card'")
    this.meta.removeTag("name='twitter:title'")
    this.meta.removeTag("name='twitter:image'")
    this.meta.removeTag("name='twitter:image:src'")
    this.meta.removeTag("property='og:type'")
    this.meta.removeTag("property='og:url'")
    this.meta.removeTag("property='og:title'")
    this.meta.removeTag("property='og:image'")
  }

  public copyUrl(): void {
    navigator.clipboard.writeText(document.location.href)
      .then(() => {
        this.clipboardCopySuccess = true;
        setTimeout(() => {
          this.clipboardCopySuccess = false;
        }, this.clipboardmessageDuration);
      })
      .catch(() => this.clipboardCopySuccess = false);
  }

  /**
   * Get forum by id
   */
  getForumById(): void {
    this.forumService.getForumById(this.forumId).subscribe(
      result => {
        this.forum = result;
        this.title = result.altTitle;
        this.metaDescription = result.altMetaDescription;

        this.titleService.setTitle(this.title);
        this.meta.addTag({
          name: 'description',
          content: this.metaDescription
        });
      },
      (error) => {
        this.serverErrorInterceptor.intercept(error)
      },
      () => {
        this.loadSuggestions();
        const imagePreview = this.sharedService.formatPreviewImage(this.forum.imageUrl);
        this.meta.addTags([
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:title', content: this.forum.title },
          { name: 'twitter:image', content: imagePreview },
          { name: 'twitter:image:src', content: imagePreview },
          { property: 'og:type', content: 'article' },
          { property: 'og:url', content: this.fullUrl },
          { property: 'og:title', content: this.forum.title },
          { property: 'og:image', content: imagePreview }
        ]);
      }
    );
  }

  closeNewsletter($event: any): void {
    this.isOpen = $event;
  }

  goBack(): void {
    this.location.back();
  }

  likeForum(): void {
    if (this.isLiked()) return;

    const currentLikes = Number(this.forum.like) || 0;
    const updatedLikes = currentLikes + 1;
    this.forum.like = updatedLikes.toString();
    this.hasLiked = true;

    const likedForums = JSON.parse(localStorage.getItem('likedForums') || '[]');
    likedForums.push({ id: this.forum.id, type: 'forum'});
    localStorage.setItem('likedForums', JSON.stringify(likedForums));

    this.forumService.updateForumLikes(this.forum.id, updatedLikes).subscribe();
  }

  isLiked(): boolean {
    const likedForums = JSON.parse(localStorage.getItem('likedForums') || '[]');
    return likedForums.some((item: any) => item.id === this.forum?.id && item.type === 'forum');
  }

  private loadSuggestions(): void {
    this.suggestions = this.forum.suggestions;
    this.suggestions.forEach(suggestion => {
      suggestion.typeArticle = TypeArticle.SUGGESTION;
    });
    
    this.suggestionService.setupSuggestions(this.suggestions,this.forum,TypeArticle.FORUM).subscribe(
      (suggestions) => {
        this.suggestions = suggestions;
      },
      (error) => {
        console.error('Error fetching suggestions', error);
      }
    );
  }

  setPopupStatus(isOpen : boolean): void {
    this.isOpenPopup = isOpen;
  }

}
