<div
  class="success-stories__list"
  [class.actualities-list_responsive-carousel]="responsiveDisplay == 'carousel'"
  aria-live="polite"
>
  <ng-container *ngFor="let successStory of successStories; let i = index">
    <dep-success-story-thumbnail
      [class.is-highlighted]="highlightFirst && i == 0"
      [isHighlighted]="highlightFirst && i == 0"
      [successStory]="successStory"
      [successStoryIndex]="i"
    >
    </dep-success-story-thumbnail>
  </ng-container>
</div>
