import { Component, Input } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';

@Component({
  selector: 'dep-testimony-card',
  templateUrl: './testimony-card.component.html',
  styleUrls: ['./testimony-card.component.scss']
})
export class TestimonyCardComponent {
  @Input() testimony: Actuality;
  public url: string;
  public truncatedDescription: string

  constructor(private urlFormatter: FormatUrlPipe) { }

  ngOnInit(): void {
    this.truncatedDescription = this.testimony.description.length > 150 ? this.testimony.description.substring(0, 150) + '...' : this.testimony.description;
  }

  replaceUrl(): string {
    this.testimony.tags.forEach((tag, index, array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length == 1) {
        this.url = "/actualites/Autres/" + this.testimony.id + '-' + this.urlFormatter.transform(this.testimony.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length > 1) {
        array.splice(index, 1)
      } else {
        this.url = "/actualites/" + this.urlFormatter.transform(array[0].name) + "/" + this.testimony.id + '-' + this.urlFormatter.transform(this.testimony.url) + '.html'
      }

    });

    return this.url;
  }
}
