import { Component, Input } from '@angular/core';

@Component({
  selector: 'dep-page-header-v2',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponentV2 {
  @Input() title: string;
  @Input() description: string;
}
