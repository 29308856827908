import { Component, Input } from '@angular/core';

@Component({
  selector: 'dep-card-v2024',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() title: string;
  @Input() text : string;
  @Input() url : string;
  @Input() isLogo : boolean = true;

  constructor() {
  
   }
  
  ngOnInit(): void {

  }

}
