import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Tag } from 'src/app/models/tags/tag.model';

@Component({
  selector: 'dep-article-header-v2',
  templateUrl: './article-header-v2.component.html',
  styleUrls: ['./article-header-v2.component.scss'],
})

export class ArticleHeaderV2Component {
  @Input() articleTitle: string;
  @Input() articleSubtitle: string;
  @Input() articleDate: string;
  @Input() articleDateTime: string;
  @Input() articleReadingTime: string;
  @Input() articleCategories:Tag[];
  @Input() title: string;
  replaceTag : boolean =true ;
  @Input() buttonIcon: string;
  @Input() buttonText: string;
  @Input() isPopover: boolean;
  public showPopup: boolean=false;
  @Output("buttonAction") buttonAction: EventEmitter<any> = new EventEmitter();
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('popover') popover: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.articleCategories){
      this.replaceTags(); 
      }
  }

  replaceTags(){
    this.articleCategories.forEach( tag => {
      if(tag.name !== 'France' && tag.name !== 'Europe'){
        this.replaceTag = false ;
      }
    });
  }

  scroll() : void{
    this.buttonAction.emit();
  }

}
