<article>
    <a [routerLink]="replaceUrl()" class="testimony">
        <div class="testimony__content">
            <div class="testimony__header">
                <p>{{testimony.publishDate | date :'d MMMM' | titlecase}}</p>
                <p>{{testimony.readingTime}}</p>
            </div>
            <div class="testimony__image">
                <img [src]="testimony.imageUrl" alt="image" class="customised">
            </div>
            <p class="title">{{testimony.title}}</p>
            <p class="description">{{truncatedDescription}}</p>
        </div>
        <div class="cta">
            <span>Lire
                <img src="/assets/images/icones/arrow_forward.svg" alt="icon arrow" class="customised">
            </span>
        </div>
    </a>
</article>