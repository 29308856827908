import { Component, Input } from '@angular/core';
import { FaqDetail } from 'src/app/models/faqs/faq-detail.model';

@Component({
  selector: 'dep-dropdown-v2',
  templateUrl: './dropdown-v2.component.html',
  styleUrls: ['./dropdown-v2.component.scss']
})
export class DropdownV2Component {
  @Input() faqDetail: FaqDetail;
  isOpen : boolean = false;

  public toggleDropdown() : void {
    this.isOpen = !this.isOpen;
  }
}
