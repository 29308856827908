import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ACTUALITIESTYPES } from 'src/app/mocks/actualities-types/mock-actualities-types';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { ActualityRequest } from 'src/app/models/actualities/actualityRequest.model';
import { EventRequest } from 'src/app/models/events/eventrequest.model';
import { Event } from 'src/app/models/events/events.model';
import { SectoralPage } from 'src/app/models/observatory/sectors/SectorialPage';
import { Podcast } from 'src/app/models/podcasts/podcast.model';
import { SectorMenu } from 'src/app/models/sectors/sector-menu.model';
import { Sector } from 'src/app/models/sectors/sector.model';
import { Study } from 'src/app/models/studies/study.model';
import { Tag } from 'src/app/models/tags/tag.model';
import { ActualityService } from 'src/app/services/actualities/actuality.service';
import { EventService } from 'src/app/services/events/event.service';
import { ObsNumberFormatterService } from 'src/app/services/observatory/obs-number-formatter.service';
import { SectoralPagesService } from 'src/app/services/observatory/sectoral-pages.service';
import { PodcastService } from 'src/app/services/podcasts/podcast.service';
import { SectorService } from 'src/app/services/sectors/sector.service';
import { StudyService } from 'src/app/services/studies/study.service';
import { ServerErrorInterceptorService } from 'src/app/shared/services/servererrorinterceptor.service';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { PodcastRequest } from 'src/app/models/podcasts/podcast-request.model';
import { SuDetails } from 'src/app/models/observatory/su-details/SuDetails';
import { SuDetailsService } from 'src/app/services/observatory/su-details.service';
import { filter } from 'rxjs/operators';
import { StudyRequest } from 'src/app/models/studies/study-request.model';

@Component({
  selector: 'dep-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss']
})
export class SectorsComponent implements OnInit, OnDestroy {
  @ViewChild('map') map: ElementRef;
  public startups: SuDetails[] = [];
  actualitiesTypes = ACTUALITIESTYPES;
  public actualities: Actuality[] = [];
  public events: Event[] = [];
  public podcasts: Podcast[] = [];
  public studies: Study[];
  public sector: Sector;
  public sectorsMenu: SectorMenu[];
  public selectedSector: number = 0;
  private title: string;
  private metaDescription: string;
  private eventRequest: EventRequest = new EventRequest;
  private actualityRequest: ActualityRequest = new ActualityRequest;
  private internationalTag: Tag;
  private sectoralPages: SectoralPage[];
  public selectedSectoralPage: SectoralPage;
  public funds: string;
  public startupsChecked: boolean = false;
  public url : string;
  public urlPage = '/discover-deeptech';
  links = [
    { fragment: 'description', label: 'Description', alt: 'Recruter' },
    { fragment: 'actualities', label: 'Actualité', alt: 'Se former' },
    { fragment: 'startups', label: 'Les startups dans ma région', alt: 'Trouver des financements' },
    { fragment: 'podcasts', label: 'Podcasts', alt: 'Être accompagné' },
    { fragment: 'etudes', label: 'Etudes', alt: 'Gagner en visibilité' }
  ];

  constructor(private renderer: Renderer2,
    private actualityService: ActualityService,
    private eventService: EventService,
    private podcastService: PodcastService,
    private studyService: StudyService,
    private sharedService: SharedService,
    private meta: Meta,
    private titleService: Title,
    private sectorService: SectorService,
    private obsSectoralPagesService: SectoralPagesService,
    private obsFormatterService: ObsNumberFormatterService,
    private serverErrorInterceptor: ServerErrorInterceptorService,
    private suDetailsService: SuDetailsService,
    private route: Router,
    private router: ActivatedRoute
   ) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit(): void {
    this.getSectoralPages();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
  }

  enableMapManipulation(): void {
    this.renderer.setStyle(this.map.nativeElement, 'pointer-events', 'auto');
  }

  disableMapManipulation(): void {
    this.renderer.setStyle(this.map.nativeElement, 'pointer-events', 'none');
  }

  /**
   * Get 5 last actualities
   */
  private getActualities(): void {
    this.actualities = [];
    this.actualityService.getActualities(this.actualityRequest).subscribe(
      result => {
        this.actualities = result.data;
      });
  }


  /**
   * Get the selected Menu
   */
  private getSelectedSectorId(): void {
     this.url = this.route.url;
     console.log("url",this.url);
    switch (this.url) {
      case '/agritech-foodtech/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Agritech et Foodtech'))?.id;

        break;
      case '/biotech/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Biotech'))?.id;


        break;
      case '/cybersecurite/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Cybersécurité'))?.id;


        break;
      case '/energie/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Energie'))?.id;
        break;
      case '/IA-software/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('IA et Software'))?.id;
        break;
      case '/industrie/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Industrie 4.0'))?.id;

        break;
      case '/medtech/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Medtech'))?.id;

        break;
      case '/mobilite-ville-durable/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Mobilité et ville durable'))?.id;

        break;
      case '/quantique/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Quantique'))?.id;

        break;
      case '/spacetech/.':
        this.selectedSector = this.sectorsMenu.find(sector => sector.title.includes('Spacetech'))?.id;
        break;

    }
    if (this.selectedSector != 0 && this.selectedSector) {
      this.getInternationalTag(this.selectedSector);
      this.getSectorDetail();
      this.getPocasts();
      this.getStudies();
    }

  }

  /**
 * Hide button 'show more'
 * show the rest of description
 */
  public getAllDescription(): void {
    this.renderer.addClass(document.getElementById('btnShowMore'), 'hide');
    this.renderer.removeClass(document.getElementById('descText'), 'description__truncate');
  }

  /**
   * Get 3 events
   */
  private getEvents(): void {
    this.eventService.getEvents(this.eventRequest).subscribe(
      result => {
        this.events = result.data;
      });
  }

  /**
   * Get podcasts
   */
  private getPocasts(): void {
    const podcastRequest: PodcastRequest = {
      start: 1,
      limit: 2,
      sectorId: this.selectedSector
    }
    this.podcasts = [];
    this.podcastService.getPodcasts(podcastRequest).subscribe(
      result => {
        this.podcasts = result.data;
      });
  }

  /**
   * Get five recent studies
   */
  getStudies(): void {
    this.studies = [];
    let studyRequest:StudyRequest = new StudyRequest();
    studyRequest.index = 1;
    studyRequest.limit = 4;
    studyRequest.sectorId =  this.selectedSector;
    this.studyService.getStudies(studyRequest).subscribe(
      result => {
        this.studies = result.data;
      });
  }

  private getSectorDetail(): void {
    this.sectorService
      .getSectorById(this.selectedSector)
      .subscribe((result) => {
        this.sector = result;
        /** SEO */
        this.title = result?.altTitle;
        this.metaDescription = result?.altMetaDescription;
        this.titleService.setTitle(this.title);
        this.meta.addTag({
          name: 'description',
          content: this.metaDescription,
        });
        this.getStartupsBySector();
      },
        (error) => {
          this.serverErrorInterceptor.intercept(error)
        },
        () => {

          this.selectedSectoralPage = this.sectoralPages.find(
            result => this.sector.title.includes(result.sector)
          );
    this.initSelectedSectoralPage(this.selectedSectoralPage);
  }
      );

}

  private initEventRequest(selectedSectorId: number, internationalTag: Tag): void {
  this.eventRequest.page = 1;
  this.eventRequest.pageSize = 3;
  this.eventRequest.startDate = new Date();
  this.eventRequest.sectorIds = [selectedSectorId];
  this.eventRequest.monthly = false;
  this.eventRequest.excludeTagId = internationalTag.id;
  this.eventRequest.technicalsTagCodes=["EPub"];

}

  private initActualityRequest(selectedSectorId: number, internationalTag ?: Tag): void {
  this.actualityRequest.page = 0;
  this.actualityRequest.pageSize = 5;
  this.actualityRequest.sectorId = selectedSectorId;
  this.actualityRequest.excludeTagId = internationalTag?.id;
  this.actualityRequest.technicalsTagCodes = ["EPub"];
}

  private async getInternationalTag(selectedSectorId: number) {
  this.internationalTag = this.sharedService.tag;
  if (this.internationalTag) {
    this.initActualityRequest(selectedSectorId, this.internationalTag);
    this.initEventRequest(selectedSectorId, this.internationalTag);
    this.getActualities();
    this.getEvents();
  } else {
    await this.sharedService.retrieveTag().then(
      (result) => {
        this.initActualityRequest(selectedSectorId, result);
        this.initEventRequest(selectedSectorId, result);
        this.getActualities();
        this.getEvents()
      }
    );
  }
}

  private getSectoralPages() {
  this.obsSectoralPagesService.getSectoralPages().subscribe(
    results => this.sectoralPages = results.filter(result => result.sector.length > 0),
    error => console.error(error),
    ()=>this.getSectorMenu()
  )
}

  private initSelectedSectoralPage(sectoralPage: SectoralPage): void {
  if(sectoralPage) {
    this.funds = this.obsFormatterService.fundsFormatter(this.selectedSectoralPage.fundsTotal);
  }
}

  private getSectorMenu() {
  this.sectorService.getMenuSectors().subscribe(
    (data) => {
      this.sectorsMenu = data;
    },
    (error) => {
      console.log('There is an error : ', error);
    },
    () => {
      this.getSelectedSectorId();

    }
  );
}

getStartupsBySector() {
  this.suDetailsService.getSuDetails().subscribe(
    results => {
      results.forEach(s => {
        if (this.sector.title.includes(s.sector) && s.sector && s.adressLat && s.adressLng) {
          this.startups.push(s);
        }
      })
      this.startupsChecked = true;
    }
    ,
    error => console.error(error)
  )
}

}
