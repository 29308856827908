import { Component, Input, OnInit } from '@angular/core';
import { Content } from 'src/app/models/dynamic-contents/content.model';
import { Forum } from 'src/app/models/forums/forum.model';

@Component({
  selector: 'dep-forum-card',
  templateUrl: './forum-card.component.html',
  styleUrls: ['./forum-card.component.scss']
})
export class ForumCardComponent  implements OnInit {

  @Input() forum: Forum;
  public content: Content;

  constructor() { }

  ngOnInit(): void {
    this.content = this.getForumContent();
    if (this.content === undefined) {
      this.content = this.forum.contents[0];
    }

    console.log(this.content);
  }

  /**
   * Get content of the citation in forum
   */
  private getForumContent(): Content {
    this.forum.contents.sort((quoteId1, quoteId2) => quoteId1.quoteId === quoteId2.quoteId ? -1 : 0);
    return this.forum.contents.find(content => (content.quote != null && content.quote != ""
      && content.authorName != null && content.authorName != ""
      && content.authorRole != null && content.authorRole != ""
      && content.authorPhotoProfile != null && content.authorPhotoProfile != ""));
  }

}
