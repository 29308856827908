import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLikes'
})
export class FormatLikesPipe implements PipeTransform {
  transform(value: string | number): string {
    const likes = Number(value) || 0;
    if (likes === 0) {
        return '';
    }
    return `${likes} Like${likes !== 1 && likes !== 0 ? 's' : ''}`;
  }
}