import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { environment } from 'src/environments/environment';
import { Study } from '../../models/studies/study.model';
import { createHttpParams } from '../../shared/utils/http-params';
import { Wrapper } from 'src/app/models/wrapper/wrapper.model';
import { StudyRequest } from 'src/app/models/studies/study-request.model';

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  private REST_API_SERVER = '';
  private STUDIES = '';
  private STUDY = '';
  private STUDIES_COUNT = '';

  constructor(private http: HttpClient, private ressourcePath: GlobalURLs) {
    this.REST_API_SERVER = environment.apiUrl;
  }

  public getStudies(request: StudyRequest): Observable<Wrapper<Study>> {

    let params = new HttpParams();
    if (request.index) {
      params = params.append("page", request.index);
    }
    if (request.limit) {
      params = params.append("pageSize", request.limit);
    }
    if (request.sectorId) {
      params = params.append("sectorId", request.sectorId);
    }
    if (request.tagIds && request.tagIds.length>0) {
      params = params.appendAll({ "tagIds": request.tagIds });
    }
    if (request.excludeIds && request.excludeIds.length>0) {
      params = params.appendAll({"excludeIds":  request.excludeIds});
    }

    this.STUDIES = this.ressourcePath.getStudies();
    return this.http.get<Wrapper<Study>>(this.REST_API_SERVER + this.STUDIES, { params });
  }

  public updateStudyLikes(id: number, likes: number): Observable<any> {
    let params = new HttpParams();
    params = params.appendAll({ "like": likes });
    return this.http.put(this.REST_API_SERVER + this.STUDY + "?like="+likes, { params});
  }

  public getStudiesCount(tagIds?: number[]): Observable<number> {
    let params: HttpParams = createHttpParams({
    });

    if (tagIds)
      params = params.appendAll({ "tagIds": tagIds })

    this.STUDIES_COUNT = this.ressourcePath.getStudiesCount();
    return this.http.get<number>(this.REST_API_SERVER + this.STUDIES_COUNT, { params });
  }

  public getStudyById(id: number): Observable<Study> {
    this.STUDY = this.ressourcePath.getStudyById(id);
    return this.http.get<Study>(this.REST_API_SERVER + this.STUDY);
  }
}
