import { Component, Input } from '@angular/core';
import { Brief } from 'src/app/models/briefs/brief.model';

@Component({
  selector: 'dep-brief-card',
  templateUrl: './brief-card.component.html',
  styleUrls: ['./brief-card.component.scss']
})
export class BriefCardComponent {
  @Input() brief: Brief;

}
