<article class="brief-card" *ngIf="brief">
    <a class="mobile-link" href="{{brief.externalLink}}" target="_blank" rel="noopener noreferrer"></a>
    <span class="date date-mobile"> {{(brief.publishDate | nbDaysElapsed)}}</span>
    <div class="brief-card__image">
        <img src="{{brief.imageUrl}}" alt="{{brief.altImage}}" loading="lazy" class="customised">
    </div>
    <div class="brief-card__content">
        <div class="brief-card__content--header">
            <span class="date"> {{(brief.publishDate | nbDaysElapsed)}}</span>
            <p class="title">{{brief.title}}</p>
        </div>
        <div class="brief-card__content--footer">
            <div class="brief-card__source">
                <img src="{{brief.logoUrl}}" alt="" class="customised">
            </div>
            <a href="{{brief.externalLink}}" target="_blank" rel="noopener noreferrer" class="ds-btn ds-btn--secondary">
                <span>Lire</span>
                <dep-icon [icon]="'icon-open_in_new'"></dep-icon>
            </a>
            <dep-icon [icon]="'icon-open_in_new'" class="icon-mobile"></dep-icon>
        </div>
    </div>
</article>