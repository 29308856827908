import { Component, Input } from '@angular/core';
import { Suggestion, TypeArticle } from 'src/app/models/suggestions/Suggestions.model';

@Component({
  selector: 'dep-suggestion-card',
  templateUrl: './suggestion-card.component.html',
  styleUrls: ['./suggestion-card.component.scss']
})
export class SuggestionCardComponent {
  @Input() btnLibelle : string = "Lire";
  @Input() suggestion : Suggestion;
  public internalLink : boolean;
  public truncatedDescription : string;


  ngOnInit(): void {
    this.truncatedDescription = this.suggestion.description.length > 150 ? this.suggestion.description.substring(0, 150) + '...' : this.suggestion.description;
    this.internalLink = this.suggestion.typeArticle === TypeArticle.SUGGESTION;
  }

  ngOnChanges(): void {
    this.internalLink = this.suggestion.typeArticle === TypeArticle.SUGGESTION;
  }
}


