
export class Suggestion {
    id: number;
    title: string;
    description: string;
    readingTime:string;
    imageUrl:string;
    publishDate:Date;
    typeArticle:TypeArticle;
    url:string;
}

export enum TypeArticle {
    ACTUALITE = "actualites",
    PODCAST = "podcasts",
    FORUM = "tribunes",
    ETUDE = "etudes",
    VIDEO = "videos",
    SUGGESTION = "SUGGESTION"
}