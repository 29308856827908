import { Component, Input, OnInit } from '@angular/core';
import { SuccessStory } from 'src/app/models/success-stories/SuccessStories.model';

@Component({
  selector: 'dep-success-stories-list',
  templateUrl: './success-stories-list.component.html',
  styleUrls: ['./success-stories-list.component.scss']
})
export class SuccessStoriesListComponent implements OnInit {
  @Input() highlightFirst: boolean = false;
  @Input() responsiveDisplay: string;
  @Input() successStories: SuccessStory[];

  constructor() { }

  ngOnInit(): void { }

}
