<div class="svg-background">
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
        <g clip-path="url(#clip0_9_164)">
            <rect width="100%" height="100%" fill="#1C2463" />
            <g style="mix-blend-mode:overlay" filter="url(#filter0_f_9_164)">
                <circle  cx="1143" cy="221" r="363" fill="#0077FF" fill-opacity="0.5" />
            </g>
            <g style="mix-blend-mode:overlay" filter="url(#filter1_f_9_164)">
                <path 
                    d="M40.8548 364.609C90.7137 326.855 275.034 54.2852 443.049 54.8933C611.064 55.5014 199.535 -339.539 68.3654 -225.993C-61.7187 -113.387 -9.00411 402.363 40.8548 364.609Z"
                    fill="url(#paint0_linear_9_164)" fill-opacity="0.8" />
            </g>
            <g filter="url(#filter2_d_9_164)">
                <circle class="animated-small-circle" cx="990.5" cy="16.5" r="99.5"
                    fill="url(#paint1_radial_9_164)" />
            </g>
        </g>
        <defs>
            <filter id="filter0_f_9_164" x="540" y="-382" width="1206" height="1206"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="120" result="effect1_foregroundBlur_9_164" />
            </filter>
            <filter id="filter1_f_9_164" x="-353.192" y="-586.5" width="1175.94" height="1293.08"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="170" result="effect1_foregroundBlur_9_164" />
            </filter>
            <filter id="filter2_d_9_164" x="887" y="-83" width="267" height="273" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dx="30" dy="40" />
                <feGaussianBlur stdDeviation="17" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9_164" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9_164" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_9_164" x1="432.777" y1="176.522" x2="-35.9024" y2="14.178"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#0077FF" />
                <stop offset="1" stop-color="#93ABFF" />
            </linearGradient>
            <radialGradient id="paint1_radial_9_164" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1027.98 -0.901349) rotate(117.261) scale(131.508)">
                <stop offset="0.235" stop-color="#6A82FB" />
                <stop offset="1" stop-color="#284CA3" />
            </radialGradient>
            <clipPath id="clip0_9_164">
                <rect width="100%" height="100%" fill="white" />
            </clipPath>
        </defs>
    </svg>
</div>
